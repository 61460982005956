<template>
  <div>
    <sedirector_app_bar />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        :title="
          'ประกาศผลการคัดเลือก ตำแหน่ง รองผู้อำนวยการ ครั้งที่ : ' +
            period_sedirectors.period_sedirector_times +
            ' ปี : ' +
            period_sedirectors.period_sedirector_year
        "
      >
        <v-card class="mb-4 pa-2">
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-card class="pa-1">
                <v-simple-table>
                  <tbody>
                    <tr>
                      <td class="text-center">
                        <h2 class="font-weight-bold" color="info">
                          จำนวนผู้สมัคร:
                          <v-btn
                            @click="sedirector_appQueryAll()"
                            fab
                            color="success"
                            class="font-weight-bold"
                            dark
                            small
                            >{{ sedirector_appCount.sumAll }}
                          </v-btn>
                          คน
                        </h2>
                      </td>
                      <td class="text-center">
                        <h2 class="font-weight-bold" color="info">
                          รับรองผล :
                          {{ sedirector_appCount.guarantees }}
                          คน
                        </h2>
                      </td>
                      <td class="text-center">
                        <h2 class="font-weight-bold" color="info">
                          ไม่รับรองผล :
                          {{ sedirector_appCount.unwarranteds }}
                          คน
                        </h2>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center">
                        <v-btn
                          rounded
                          color="info"
                          :href="
                            '#/admin/print_sedirector_app_score_notice/?times=' +
                              period_sedirectors.period_sedirector_times +
                              '&years=' +
                              period_sedirectors.period_sedirector_year
                          "
                          target="_blank"
                          ><v-icon>mdi-printer</v-icon>ส่งออกข้อมูลผู้สมัคร
                        </v-btn>
                      </td>

                      <td class="text-center">
                        <div>
                          <v-btn
                            @click="scoreUPdaterang()"
                            rounded
                            color="success"
                          >
                          <v-icon>mdi-numeric-1-box</v-icon>
                            จัดการคะแนน
                          </v-btn>
                        </div>

                        <div>
                          <v-btn @click="UPdaterang()" rounded color="success">
                            <v-icon>mdi-numeric-2-box</v-icon>
                            จัดลำดับ
                          </v-btn>
                        </div>
                      </td>
                      <td class="text-center">
                        <div>
                          <v-btn
                            @click="sedirector_app_ipanoticeSubmit()"
                            rounded
                            color="success"
                            >ประกาศผลการคัดเลือก</v-btn
                          >
                        </div>                      
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table></v-card
              >
            </v-col>
          </v-row>
        </v-card>
        <v-select
          v-model="selectedHeaders"
          :items="headers"
          label="เลือกคอลัมน์ที่ต้องการแสดง"
          multiple
          outlined
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 6">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 6" class="grey--text caption"
              >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
            >
          </template>
        </v-select>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        />
        <v-data-table
          color="success"
          :loading="loading"
          :headers="showHeaders"
          :items="sedirector_apps"
          :search="search"
          item-key="sedirector_app_idref"
          :item-class="row_classes"
        >
          <template v-slot:item.actions="{ item }">
            <div v-if="item.sedirector_app_ipanotice === 'Accept'">
              <v-chip color="info">ประกาศ</v-chip>
            </div>
            <div v-else>
              <div
                v-if="item.sedirector_app_id === editedItem.sedirector_app_id"
              >
                <v-icon color="red" class="mr-3" @click="close">
                  mdi-window-close
                </v-icon>
                <v-icon color="green" @click="save()">
                  mdi-content-save
                </v-icon>
              </div>
              <div v-else>
                <v-icon color="green" class="mr-3" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon
                  color="red"
                  @click="deleteItem(item.sedirector_app_idref)"
                >
                  mdi-delete
                </v-icon>
              </div>
            </div>
          </template>
          <template v-slot:item.actionstatuss="{ item }">
            <div>
              <v-select
                v-model="editedItem.sedirector_app_ipastatus"
                :items="sedirector_app_ipastatus"
                item-text="text"
                item-value="value"
                label="สถานะ"
                v-if="item.sedirector_app_id === editedItem.sedirector_app_id"
                :rules="[v => !!v || '']"
              ></v-select>
              <div color="grey" dark v-else>
                <v-chip
                  color="success"
                  dark
                  v-if="item.sedirector_app_ipastatus === 'guarantee'"
                  >รับรอง</v-chip
                >

                <v-chip
                  color="red"
                  dark
                  v-if="item.sedirector_app_ipastatus === 'unwarranted'"
                  >ไม่รับรอง</v-chip
                >
              </div>
            </div>
          </template>

          <template v-slot:item.sedirector_app_score_c1="{ item }">
            {{ item.sedirector_app_score_c1 }}
          </template>

          <template v-slot:item.sedirector_app_pvcscore_Ball="{ item }">
            {{
              calBscore(
                item.sedirector_app_pvcscore_B,
                item.sedirector_app_pvcscore_B_2,
                item.sedirector_app_pvcscore_B_3
              )
            }}
          </template>

          <template v-slot:item.sedirector_app_score_call="{ item }">
            {{
              calCscore(
                item.sedirector_app_score_c1,
                item.sedirector_app_score_c2
              )
            }}
          </template>

          <template v-slot:item.sedirector_app_score_sum_all="{ item }">
            <v-chip color="info" dark>
              {{
                calAllscore(
                  item.sedirector_app_pvcscore_A,
                  item.sedirector_app_pvcscore_B,
                  item.sedirector_app_pvcscore_B_2,
                  item.sedirector_app_pvcscore_B_3,
                  item.sedirector_app_score_c1,
                  item.sedirector_app_score_c2
                )
              }}
            </v-chip>
          </template>

          <template v-slot:item.sedirector_app_score_avg_all="{ item }">
            <v-chip
              color="success"
              dark
              v-if="
                calAllscoreAvg(
                  item.sedirector_app_pvcscore_A,
                  item.sedirector_app_pvcscore_B,
                  item.sedirector_app_pvcscore_B_2,
                  item.sedirector_app_pvcscore_B_3,
                  item.sedirector_app_score_c1,
                  item.sedirector_app_score_c2
                ) >= 60
              "
            >
              {{
                calAllscoreAvg(
                  item.sedirector_app_pvcscore_A,
                  item.sedirector_app_pvcscore_B,
                  item.sedirector_app_pvcscore_B_2,
                  item.sedirector_app_pvcscore_B_3,
                  item.sedirector_app_score_c1,
                  item.sedirector_app_score_c2
                )
              }}
            </v-chip>

            <v-chip
              color="warning"
              dark
              v-else-if="
                calAllscoreAvg(
                  item.sedirector_app_pvcscore_A,
                  item.sedirector_app_pvcscore_B,
                  item.sedirector_app_pvcscore_B_2,
                  item.sedirector_app_pvcscore_B_3,
                  item.sedirector_app_score_c1,
                  item.sedirector_app_score_c2
                ) < 60
              "
            >
              {{
                calAllscoreAvg(
                  item.sedirector_app_pvcscore_A,
                  item.sedirector_app_pvcscore_B,
                  item.sedirector_app_pvcscore_B_2,
                  item.sedirector_app_pvcscore_B_3,
                  item.sedirector_app_score_c1,
                  item.sedirector_app_score_c2
                )
              }}
            </v-chip>
          </template>

          <template v-slot:item.sedirector_app_ipanotice="{ item }">
            <div>
              <v-icon
                color="warning"
                v-if="item.sedirector_app_ipanotice === 'Accept'"
                >mdi-cube-send</v-icon
              >
            </div>
            {{ item.sedirector_app_idnoaccount }}
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>{{ snackbar.text }}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import sedirector_app_bar from "../../../components/admin/sedirector_app_bar";
import { async } from "q";

export default {
  data() {
    return {
      value: "2",
      loading: false,
      ApiKey: "HRvec2021",
      position: "ครู",
      branch_s: [],
      sedirector_app_idref: [],
      valid: true,
      times_select: "1",
      years_select: "2565",
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      sedirector_app_time: [1, 2],
      year_ss: [2565, 2566, 2567, 2568, 2569, 2570],
      sedirector_apps: [],
      editsedirector_app: {},
      search: "",
      pagination: {},
      singleSelect: false,
      selected: [],
      headers: [],
      selectedHeaders: [],
      headersMap: [
        {
          text: "ประกาศ",
          align: "center",
          value: "sedirector_app_ipanotice"
        },
        { text: "จังหวัด", align: "center", value: "province_name" },
        {
          text: "รหัสบัตรประชาชน",
          align: "center",
          value: "sedirector_app_idcard"
        },
        { text: "ชื่อ-นามสกุล", align: "left", value: "fristnames" },
        { text: "สถานศึกษาปัจจุบัน", align: "left", value: "user_firstname" },
        { text: "ดำเนินการ", align: "center", value: "actions" },
        { text: "สถานะ", align: "center", value: "actionstatuss" },

        {
          text: "ภาค ก.",
          align: "center",
          value: "sedirector_app_pvcscore_A"
        },
        {
          text: "ภาค ข.",
          align: "center",
          value: "sedirector_app_pvcscore_Ball"
        },

        {
          text: "ภาค ค.",
          align: "center",
          value: "sedirector_app_score_call"
        },

        {
          text: "รวม",
          align: "center",
          value: "sedirector_app_score_sum_all"
        },
        {
          text: "ร้อยละ",
          align: "center",
          value: "sedirector_app_score_avg_all"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      period_sedirectors: [],
      sedirector_appCount: {},
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
        value => !!value || ""
      ],

      editedIndex: -1,
      editedItem: {
        sedirector_app_id: 0,
        statusAdd: ""
      },
      editedItemUpdate: {},
      defaultItem: {
        sedirector_app_id: 0,
        statusAdd: "add"
      },

      deletedItemmat: {},
      deletedItemmcm: {},
      sedirector_appsidrefs: [],
      updatesedirector_apps: {},
      scoreSum: [],
      sedirector_app_ipastatus: [
        { text: "รับรอง", value: "guarantee" },
        { text: "ไม่รับรอง", value: "unwarranted" }
      ],
      update_sedirector_apps: {}
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },

  async mounted() {
    await this.period_sedirectorQuery();
    await this.sedirector_appQueryAll();
  },
  methods: {
    async scoreUPdaterang() {
      this.update_sedirector_apps.ApiKey = this.ApiKey;
      let data = this.sedirector_apps;
      data.forEach(async value => {
        this.update_sedirector_apps.sedirector_app_idref =
          value.sedirector_app_idref;
        this.update_sedirector_apps.sedirector_app_score_sumall =
          value.sumscoreall;
        this.update_sedirector_apps.sedirector_app_score_avgall =
          value.avgsocre;
        let result = await this.$http.post(
          "sedirector_app.update.score.php",
          this.update_sedirector_apps
        );
        
      });
      await this.sedirector_appQueryAll();
    },

    async UPdaterang() {
      this.update_sedirector_apps.ApiKey = this.ApiKey;
      let data = this.sedirector_apps;
      data.forEach(async (value, index) => {
        this.update_sedirector_apps.sedirector_app_idref =
          value.sedirector_app_idref;
        this.update_sedirector_apps.sedirector_app_idnoaccount = index + 1;
        let result = await this.$http.post(
          "sedirector_app.update.score.php",
          this.update_sedirector_apps
        );
        
      });
      await this.sedirector_appQueryAll();
    },

    async editItem(item) {
      this.editedIndex = this.sedirector_apps.indexOf(item);
      this.editedItem = Object.assign({}, item);

      let result = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: item.sedirector_app_idref
      });
      this.sedirector_appsidrefs = result.data;
    },

    close() {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async save() {
      this.editedItem.ApiKey = this.ApiKey;
      Object.assign(this.sedirector_apps[this.editedIndex], this.editedItem);

      let result = await this.$http.post(
        "sedirector_app.update.php",
        this.editedItem
      );
      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "บันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "ทำรายการซ้ำ",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.sedirector_appQueryAll();
      this.close();
    },
    async deleteItem(sedirector_app_idref) {
      let result = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.sedirector_appsidrefs = result.data;
      this.sedirector_appsidrefs.ApiKey = this.ApiKey;
      this.sedirector_appsidrefs.sedirector_app_score_c1 = "";
      this.sedirector_appsidrefs.sedirector_app_score_c2 = "";
      let fristnames = this.sedirector_appsidrefs.fristnames;
      Swal.fire({
        title: "คุณต้องการยกเลิกคะแนนรายการนี้?",
        text: fristnames,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ลบรายการ",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          let resultupdatemat = await this.$http.post(
            "sedirector_app.update.php",
            this.sedirector_appsidrefs
          );
      
        }
        this.sedirector_appQueryAll();
      });
    },

   
    async sedirector_app_ipanoticeSubmit() {
      Swal.fire({
        title: "ต้องการประกาศผลการคัดเลือก?",
        text: "ตำแหน่ง รองผู้อำนวยการวิทยาลัย",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ประกาศ",
        cancelButtonText: "ยกเลิก",
        denyButtonText: `ยกเลิกประกาศ`
      }).then(async result => {
        if (result.isConfirmed) {
          this.update_sedirector_apps.ApiKey = this.ApiKey;
          this.update_sedirector_apps.sedirector_app_year = this.period_sedirectors.period_sedirector_year;
          this.update_sedirector_apps.sedirector_app_time = this.period_sedirectors.period_sedirector_times;
          this.update_sedirector_apps.sedirector_app_ipanotice = "Accept";

          let resultupdatemat = await this.$http.post(
            "sedirector_app.update.admin.php",
            this.update_sedirector_apps
          );       
          if (resultupdatemat.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.sedirector_appQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }else if (result.isDenied) {
          this.update_sedirector_apps.ApiKey = this.ApiKey;
          this.update_sedirector_apps.sedirector_app_year = this.period_sedirectors.period_sedirector_year;
          this.update_sedirector_apps.sedirector_app_time = this.period_sedirectors.period_sedirector_times;
          this.update_sedirector_apps.sedirector_app_ipanotice = "";

          let resultupdatemat = await this.$http.post(
            "sedirector_app.update.admin.php",
            this.update_sedirector_apps
          );       
          if (resultupdatemat.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.sedirector_appQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
       
      });
    },
    calBscore(a, b, c) {
      let aa = parseFloat(a) || 0;
      let bb = parseFloat(b) || 0;
      let cc = parseFloat(c) || 0;
      let sum = parseFloat(aa) + parseFloat(bb) + parseFloat(cc);
      let result = parseFloat((sum * 100) / 150).toFixed(2);
      return result;
    },

    calCscore(a, b) {
      let aa = parseFloat(a) || 0;
      let bb = parseFloat(b) || 0;
      let sum = parseFloat(aa) + parseFloat(bb);
      let result = parseFloat((sum * 100) / 100).toFixed(2);
      return result;
    },

    calAllscore(a, b, c, d, e, f) {
      let aa = parseFloat(a) || 0;
      let bb = parseFloat(b) || 0;
      let cc = parseFloat(c) || 0;
      let dd = parseFloat(d) || 0;
      let ee = parseFloat(e) || 0;
      let ff = parseFloat(f) || 0;
      let result =
        parseFloat(aa) +
        parseFloat(bb) +
        parseFloat(cc) +
        parseFloat(dd) +
        parseFloat(ee) +
        parseFloat(ff);
      return result;
    },

    calAllscoreAvg(a, b, c, d, e, f) {
      let aa = parseFloat(a) || 0;
      let bb = parseFloat(b) || 0;
      let cc = parseFloat(c) || 0;
      let dd = parseFloat(d) || 0;
      let ee = parseFloat(e) || 0;
      let ff = parseFloat(f) || 0;
      let sum =
        parseFloat(aa) +
        parseFloat(bb) +
        parseFloat(cc) +
        parseFloat(dd) +
        parseFloat(ee) +
        parseFloat(ff);
      let result = parseFloat((sum * 100) / 350).toFixed(2);
      this.scoreSum = result;
      return result;
    },

    row_classes(item) {
      if (item.id_position_new > 0) {
        return "info";
      } else if (item.ccDocumentFile == "cc") {
        return "yellow";
      } else if (item.ccDocumentFilePVC == "cc") {
        return "yellow";
      }
    },

    async period_sedirectorQuery() {
      let result_period_sedirector;
      result_period_sedirector = await this.$http.post(
        "period_sedirector.php",
        {
          ApiKey: this.ApiKey,
          period_sedirector_process: "1",
          period_sedirector_id: "201"
        }
      );
      this.period_sedirectors = result_period_sedirector.data;
    },

    async sedirector_appQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_time: this.period_sedirectors.period_sedirector_times,
          sedirector_app_year: this.period_sedirectors.period_sedirector_year,
          sedirector_app_pvc_approve: "pass"
        })
        .finally(() => (this.loading = false));
      this.sedirector_apps = result.data;

      let data = this.sedirector_apps;
      let sum = 0;
      let unwarranteds = 0;
      let guarantees = 0;
      data.forEach(value => {
        if (value.sedirector_app_pvc_approve == "pass") {
          sum += 1;
        }
        if (value.sedirector_app_ipastatus == "guarantee") {
          guarantees += 1;
        } else {
          unwarranteds += 1;
        }
      });
      this.sedirector_appCount.sumAll = sum;
      this.sedirector_appCount.unwarranteds = unwarranteds;
      this.sedirector_appCount.guarantees = guarantees;
    },

    getColor(calories) {
      if (calories > 0) return "green";
      else return "";
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    time_stamp() {
      let time = Date.now();
      return time;
    },
    period_sedirector_years() {
      let yyyy = this.period_sedirectors.period_sedirector_year;
      return yyyy;
    },
    color() {
      return "lime darken-4";
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  },
  components: { sedirector_app_bar }
};
</script>

<style scoped>
.orange {
  background-color: rgb(236, 194, 115);
}
</style>
